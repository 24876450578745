.Landing {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}
.Landing{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #cdd1d7;
  height: 100vh;
}
.greeting{
    text-align: center;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.start__link{
    text-decoration: none;
    color: #212529;
    font-weight: bold;
    font-size: 1.2em;
    padding: 0.5vh;
    transition: 0.3s;
}
.start:hover{
    background: white;
    transition: all 0.5s;
    
}
.start{
    text-align: center;
    position: relative;
    top: 3vh;
    border: 3px solid #212529;
    width: 10%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media(max-width: 820px){
    .start{
        width: 30%;
    }
}
@media(max-width: 330px){
    .start{
        width: 40%;
    }
}


