.burger-btn{
    width: 30px;
    height: 20px;
    margin-left: 2vw;
    cursor: pointer;
}
.burger-btn:before{
    content: '';
    position: absolute;
    top: 3.5vh;
    left:5vw;
    width: 25px;
    background-color: white;
    height: 2px;
}
.burger-btn:after{
    content: '';
    position: absolute;
    bottom: 0;
    top: 4.5vh;
    left:5vw;
    width: 25px;
    background-color: white;
    height: 2px;
}
.burger-btn span{
    position: absolute;
    top: 5.5vh;
    left:5vw;
    width: 25px;
    background-color: white;
    height: 2px;
}

