.menu {
  position: fixed;
  width: 110vw;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  left: 0;
  top: -1vh;
  transform: translateX(-210%);
  transition: all 0.2s;
  z-index: 5
}
.menu.active{
    transform: translateX(-10%);
    height: 100vh;
    overflow-y: hidden;
}
.blur{
    width: 100vw;
    height: 100%;
    left: 30%;
    backdrop-filter: blur(2px);
    position: absolute;
}
.menu__content{
    width: 60%;
    height: 100%;
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.4s;
    

}
.menu__header{
    padding: 10px;
    font-size: 2rem;
    color: white;
    border-bottom: 1px solid white;
    width: 100%;
    text-align: center;
    position: relative;
    margin-bottom: 10vh;
}
ul li .link{
    color: white;
    text-decoration: none;
    font-size: 1.3rem;
}
ul{
    text-align: center;
    position: relative;
    list-style: none;
    right: 1vw;
    top: -10vh
}
@media(max-width: 530px){
    .menu__content{
        width: 60%;
    }
}
@media(max-width: 300px){
    .menu__content{
        width: 70%;
    }
}
