.Info {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.Info {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #cdd1d7;
  min-height: 100vh;
  height: auto;
}
.information{
    text-align: center;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
h4{
    width: 50%;
    color: #212529;
    word-wrap: break-word;
    white-space: pre-wrap;
}
.hided{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: none;
}
.blocked{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.button{
    text-decoration: underline;
    cursor: pointer;
}
.button:hover{
    color: white;
    transition: all 0.3s;
}
