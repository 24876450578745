.formblock {
  background: #f3f4f9;
  width: 60%;
  height: auto;
  border-radius: 1.5em;
  box-shadow: 0 0 10px #555;
  margin-top: 5vh;
}
.buttons{
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-bottom: 6vh;

}
.buttons h2{
  margin-bottom: 0.5vh;
}
button:first-child{
  margin-top: 3vh;
  margin-bottom: 1vh;
}

.zagol{
    margin-left: 6vw;
    margin-bottom: -3vh;
}
.flex_file{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.h4{
  margin-left: 6vw;
  font-weight: bold;
  font-size: 1.5em;
  margin-top: 2vh;
}
@media(max-height: 800px){
  .formblock{
    margin-top: 13vh;
  }
}
@media(max-width: 1000px){
  .formblock{
    width: 90%;
  }
}