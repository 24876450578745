.navbar{
    width: 100%;
    height: 10vh;

    font-size: 1.7em;
    background-color: #212529;
    font-weight: bold;
    font-family: 'Joti One', serif;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: 0 0 10px #555;
}
.main{
    width:95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.navbar>div{
    position: absolute;
    left: 2vw;
    top: 0;
    display: none;
}
.cont{
   position: absolute;
   top: 1vh;
}
.cont ul{
    display: flex;
    top: 10vh;
    left: 0.3vw;
    
}
.cont li{
    margin-right: 2vw;
}
.link{
    font-size: 0.95em;
    text-decoration: none;
    color: #212529;
    font-weight: bold;
    
}
.link:hover{
    color: white;
    transition: all 0.3s;
}


@media(max-width: 1000px){
    .navbar div{
        display: block;
    }
    .cont{
        display: none;
    }
}
@media(min-width: 10001px){
    .cont{
        display: block;
    }
}
@media(max-height: 500px){
    .navbar{
        height: 15vh;
    }
    .cont{
        top: 7vh;
    }
}