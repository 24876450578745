@import url('https://fonts.googleapis.com/css2?family=Joti+One&family=Playfair+Display&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #cdd1d7;
  height: 100%;
}
@media print {
  body, html {
    margin: 0;
    padding: 0;
    height: 100%;
  }
  .ouf{
    height: 100%;
    width: 100%;
  }
}